import React from 'react';
import SEO from '@/components/Seo';
import Layout from '@/components/Layout';
import ReactIcon from '@/assets/svg/react.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Developer" />
    <h1>[WIP] Hi,</h1>
    <p>I'm Dan Radenkovic, a developer based (mainly) in Belgrade, Serbia.</p>
    <h2>Education</h2>
    <ul>
      <li>Medicine, University of Nis Doctor of medicine (2008-2014)</li>
      <li>Stanford MOOC</li>
      <li>University of Toronto MOOC</li>
    </ul>
    <h2>Experience</h2>
    <ul>
      <li>Nylas</li>
      <li>BeyondImagine</li>
      <li>June.ai</li>
      <li>Entrecloud</li>
      <li>Liveclinic</li>
      <li>Panasonic</li>
      <li>Tufts University</li>
    </ul>
    <h2>Development</h2>
    <ul>
      <li>Full Stack Development</li>
      <li>Machine Learning & Data Science</li>
    </ul>
    <h2>Javascript Ecosystem</h2>
    <ul>
      <li>
        <img src={ReactIcon} width={16} alt="React Developer" /> React
      </li>
      <li>React Native</li>
      <li>Vue</li>
      <li>Angular</li>
      <li>NextJS</li>
      <li>Redux</li>
      <li>Nuxt</li>
      <li>Vuex</li>
      <li>RxJS</li>
      <li>D3.js</li>
      <li>Jest</li>
      <li>Mocha</li>
      <li>Puppeteer</li>
      <li>Styled Components</li>
      <li>Express</li>
      <li>Eslint</li>
      <li>Prettier</li>
      <li>Flow</li>
      <li>Typescript</li>
      <li>WebWorkers</li>
      <li>Webpack</li>
      <li>Gulp</li>
      <li>Babel</li>
      <li>npm</li>
      <li>yarn</li>
      <li>Chart.js</li>
      <li>Gatsby</li>
      <li>Gridsome</li>
      <li>Electron</li>
      <li>Pug</li>
      <li>FeathersJS</li>
      <li>Osmosis</li>
      <li>Koa</li>
      <li>Apollo GraphQL</li>
      <li>Mustache</li>
      <li>Nunjucks</li>
      <li>OpenTok</li>
      <li>knex.js</li>
      <li>moongose</li>
    </ul>

    <h2>Machine Learning & Data Science (Python)</h2>
    <p>Mainly computer vision and NLP.</p>
    <ul>
      <li>Conda Ecosystem</li>
      <li>Tensorflow</li>
      <li>Keras</li>
      <li>OpenCV</li>
      <li>Scikit-learn</li>
      <li>CoreNLP</li>
      <li>SpaCY</li>
      <li>NLTK</li>
      <li>Seaborn</li>
      <li>Bokeh</li>
      <li>Matplotlib</li>
      <li>Beautiful Soup</li>
      <li>Flask</li>
      <li>BioPython</li>
    </ul>

    <h2>Databases & Storage</h2>
    <ul>
      <li>Postgres</li>
      <li>MySQL</li>
      <li>SQLIte</li>
      <li>MongoDB</li>
      <li>Redis</li>
      <li>RethinkDB</li>
      <li>WatermelonDB</li>
      <li>IndexedDB</li>
      <li>Algolia</li>
    </ul>

    <h2>CI/CD & Other</h2>
    <ul>
      <li>AWS Lambda</li>
      <li>AWS S3</li>
      <li>AWS Cloudfront</li>
      <li>AWS ECS & ECR</li>
      <li>AWS SageMaker</li>
      <li>AWS SQS</li>
      <li>AWS SNS</li>
      <li>AWS Route 53</li>
      <li>Gitlab CI</li>
      <li>Travis</li>
      <li>Docker</li>
      <li>PHP</li>
      <li>SASS</li>
      <li>GraphQL</li>
      <li>ElasticSearch</li>
      <li>Websockets</li>
      <li>PostGIS</li>
      <li>Nginx</li>
    </ul>

    <h2>Languages</h2>
    <ul>
      <li>English</li>
      <li>Serbian</li>
    </ul>

    <h2>Personal</h2>
    <ul>
      <li>Unwarp.io</li>
      <li>Pentameter</li>
      <li>Mariner</li>
    </ul>

    <h2>Medicine-specific</h2>
    <ul>
      <li>DNA Sequence Alignment & Exploration (BLAST)</li>
      <li>RxNorm</li>
      <li>AccessGUDID</li>
      <li>DrugBank</li>
      <li>OpenFDA</li>
      <li>FHIR</li>
      <li>UMLS</li>
      <li>WHO ICD-ICD 10 API</li>
    </ul>
  </Layout>
);

export default IndexPage;
